<template>
  <ion-app>
    
    <!-- <audio controls ref="audiosound"
        src="../assets/beep.mp3" autoplay>
         Your browser does not support the
            <code>audio</code> element.</audio> -->
    <!-- <ion-split-pane content-id="main-content">
      <ion-menu content-id="main-content" type="overlay">
        <ion-content>
          <ion-list id="inbox-list">
            <ion-list-header>Inbox</ion-list-header>
            <ion-note>hi@ionicframework.com</ion-note>
  
            <ion-menu-toggle auto-hide="false" v-for="(p, i) in appPages" :key="i">
              <ion-item @click="selectedIndex = i" router-direction="root" :router-link="p.url" lines="none" detail="false" class="hydrated" :class="{ selected: selectedIndex === i }">
                <ion-icon slot="start" :ios="p.iosIcon" :md="p.mdIcon"></ion-icon>
                <ion-label>{{ p.title }}</ion-label>
              </ion-item>
            </ion-menu-toggle>
          </ion-list>
  
          <ion-list id="labels-list">
            <ion-list-header>Labels</ion-list-header>
  
            <ion-item v-for="(label, index) in labels" lines="none" :key="index">
              <ion-icon slot="start" :ios="bookmarkOutline" :md="bookmarkSharp"></ion-icon>
              <ion-label>{{ label }}</ion-label>
            </ion-item>
          </ion-list>
        </ion-content>
      </ion-menu> -->
      <!-- id="main-content" -->
      <ion-router-outlet></ion-router-outlet>
    <!-- </ion-split-pane> -->
  </ion-app>
</template>

<script>
import { IonApp, IonRouterOutlet, toastController, //isPlatform 
} from '@ionic/vue';
import { defineComponent, watch, computed, onMounted, ref 
} from 'vue';
import { useStore } from 'vuex';
import { Network } from '@capacitor/network';

import moment from 'moment';

export default defineComponent({
  name: 'App',
  components: {
    IonApp, IonRouterOutlet
  },
  setup() {
    const store = useStore()
    const appDate = computed(() => store.getters['appDate'] )
    //const beep = ref(null)
    const audiosound = ref(null)

    const errorMessage = computed(()=>store.getters.errorMessage)

    function loadBeep() {
       audiosound.value = new Audio('../assets/beep.mp3'); // path to file
    }

    function playBeep() {
      audiosound.value.play().catch((e)=>{console.log(e);})
    }

    watch(errorMessage, ()=>{ console.log('watch', errorMessage);
        if(errorMessage.value!='')
          toastController
            .create({
              message: errorMessage.value,
              color: 'danger',
              duration: 4000,
            }).then((toast) => toast.present());
        store.commit('setErrorMessage', '')
    })
    onMounted(loadBeep)
    onMounted(()=>{
        store.commit('setErrorMessage', '')

        Network.getStatus().then(({ connected }) => { console.log('isOnline',connected);
            if (connected) {
                //this.isOnline = true;
                store.dispatch('watchMonitor');
            } else {
                //this.isOnline = false;
            }

            store.commit('setInternetConnected', connected)

        });

        Network.addListener('networkStatusChange', ({ connected }) => { console.log('isOnline',connected);
            if (connected) {
                //this.isOnline = true;
                store.dispatch('watchMonitor');
            } else {
                //this.isOnline = false;
            }

            store.commit('setInternetConnected', connected)

        });
    })



    onMounted(() => {
            store.dispatch('onUpdatePax', (msg)=> { console.log('CENTRAL APP', msg);
                const data = msg?.EXCURSIONS?.[0] || false
                const data2 = msg?.EXCURSIONS?.[1] || false
                if(data || data2){
                  if(data) data.DATE = data.DATE?moment(data.DATE).format('YYYY-MM-DD'):false
                  if(data2) data2.DATE = data2.DATE?moment(data2.DATE).format('YYYY-MM-DD'):false
                  if(appDate.value == data.DATE||appDate.value == data2.DATE) { 
                    try{
                    playBeep(); 
                    }
                    catch (error) {
                      console.log(error);
                    }
                  }

                    if(data && appDate.value == data.DATE)
                      store.commit('pushAlertPickups', {
                        Pickup:data.PICKUP,
                        Excursion:data.EXCURS,
                        //ExcursCode:data.ExcursCode,
                        Date:data.DATE,
                        Time : moment()
                      })
                    if(data && appDate.value == data2.DATE)
                      store.commit('pushAlertPickups', {
                        Pickup:data2.PICKUP,
                        Excursion:data2.EXCURS,
                        //ExcursCode:data.ExcursCode,
                        Date:data2.DATE,
                        Time : moment()
                      })
                }
                
            })
        })

    return { errorMessage, audiosound }
  }
});
</script>

<style scoped>
/* ion-menu ion-content {
  --background: var(--ion-item-background, var(--ion-background-color, #fff));
}

ion-menu.md ion-content {
  --padding-start: 8px;
  --padding-end: 8px;
  --padding-top: 20px;
  --padding-bottom: 20px;
}

ion-menu.md ion-list {
  padding: 20px 0;
}

ion-menu.md ion-note {
  margin-bottom: 30px;
}

ion-menu.md ion-list-header,
ion-menu.md ion-note {
  padding-left: 10px;
}

ion-menu.md ion-list#inbox-list {
  border-bottom: 1px solid var(--ion-color-step-150, #d7d8da);
}

ion-menu.md ion-list#inbox-list ion-list-header {
  font-size: 22px;
  font-weight: 600;

  min-height: 20px;
}

ion-menu.md ion-list#labels-list ion-list-header {
  font-size: 16px;

  margin-bottom: 18px;

  color: #757575;

  min-height: 26px;
}

ion-menu.md ion-item {
  --padding-start: 10px;
  --padding-end: 10px;
  border-radius: 4px;
}

ion-menu.md ion-item.selected {
  --background: rgba(var(--ion-color-primary-rgb), 0.14);
}

ion-menu.md ion-item.selected ion-icon {
  color: var(--ion-color-primary);
}

ion-menu.md ion-item ion-icon {
  color: #616e7e;
}

ion-menu.md ion-item ion-label {
  font-weight: 500;
}

ion-menu.ios ion-content {
  --padding-bottom: 20px;
}

ion-menu.ios ion-list {
  padding: 20px 0 0 0;
}

ion-menu.ios ion-note {
  line-height: 24px;
  margin-bottom: 20px;
}

ion-menu.ios ion-item {
  --padding-start: 16px;
  --padding-end: 16px;
  --min-height: 50px;
}

ion-menu.ios ion-item.selected ion-icon {
  color: var(--ion-color-primary);
}

ion-menu.ios ion-item ion-icon {
  font-size: 24px;
  color: #73849a;
}

ion-menu.ios ion-list#labels-list ion-list-header {
  margin-bottom: 8px;
}

ion-menu.ios ion-list-header,
ion-menu.ios ion-note {
  padding-left: 16px;
  padding-right: 16px;
}

ion-menu.ios ion-note {
  margin-bottom: 8px;
}

ion-note {
  display: inline-block;
  font-size: 16px;

  color: var(--ion-color-medium-shade);
}

ion-item.selected {
  --color: var(--ion-color-primary);
} */
</style>
