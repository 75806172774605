<template>
  <ion-label position="stacked">
    {{ label }}

    <ErrorMessage
      class="error-message-label"
      :name="name" />
  </ion-label>

  <ion-button
    fill="clear"
    @click="clickedTrigger"
    :disabled="fieldAttrs.disabled" 
    :id="fieldID">
    <ion-input 
      :value="value"
      :class="classes" 
      v-bind="{...fieldAttrs, ...{type:'text'}}"
    />
    <ion-icon :icon="calendarIcon" />
  </ion-button>
  <ion-popover :trigger="fieldID" :show-backdrop="false">
    <ion-datetime
      @click="clickedDate"
      v-model="value"
      :value="value"
      :locale="locale"
      presentation="date"
      :min="fieldAttrs.min"
      :max="fieldAttrs.max"
      :disabled="fieldAttrs.disabled"
      :yearValues="fieldAttrs.yearValues"
      :monthValues="fieldAttrs.monthValues"
      :dayValues="fieldAttrs.dayValues"
    />
  </ion-popover>
</template>

<script>
import moment from 'moment';
import { ErrorMessage } from 'vee-validate';
import {
  IonLabel, IonPopover, IonDatetime, IonButton, IonIcon, popoverController, IonInput,
} from '@ionic/vue';
import { calendarOutline as calendarIcon } from 'ionicons/icons';
import { mapState } from 'vuex';

export default {
  components: {
    IonLabel,
    IonButton,
    IonPopover,
    IonDatetime,
    IonIcon,
    IonInput,

    ErrorMessage,
  },
  emits: ['changeField'],
  data() {
    return {
      currentMonth: this.fieldAttrs.currentMonth || moment().month() + 1,
      currentYear: this.fieldAttrs.currentYear || moment().year(),
      moment,
      calendarIcon,
    };
  },
  props: {
    fieldID: {
      type: String,
      required: true,
    },
    fieldAttrs: {
      type: Object,
      required: true,
    },
    values: {
      type: Object,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    classes:String
  },
  computed: {
    ...mapState(['locale']),
    value: {
      get() {
        return this.values[this.name];
      },
      set(value) {
        popoverController.dismiss();
        console.log('SET',this.fieldAttrs);
        if (typeof this.fieldAttrs.change === 'function') {
          this.fieldAttrs.change(value);
        }
        this.change(value);
        return value;
      },
    },
  },
  methods: {
    change(value) {
      this.$emit('changeField', value);
    },
    changedView(data) {
      if (typeof this.fieldAttrs.changedView === 'function') {
        this.fieldAttrs.changedView({
          year: this.currentYear,
          month: this.currentMonth,
          ...data,
        });
      }
    },
    clickedDate(v) {
      const path = v.composed && v.composedPath ? v.composedPath() : v.path;

      if (path.find((a) => a.className && a.className.includes('calendar-next-prev'))) {
        const buttonContainer = path.find((a) => a.className && a.className.includes('calendar-next-prev')).children[0];
        let [, , outerButtonClicked, buttonClicked] = path;
        buttonClicked = buttonClicked.children[0].svgContent;

        if (!buttonClicked) {
          buttonClicked = outerButtonClicked.children[0].svgContent;
        } else {
          outerButtonClicked = null;
        }

        let [prev, next] = buttonContainer.children;
        prev = prev.children[0].svgContent;
        next = next.children[0].svgContent;

        if (prev === buttonClicked) {
          v.target.prevMonth();

          if (this.currentMonth === 1) {
            this.currentMonth = 12;
            this.currentYear -= 1;
          } else {
            this.currentMonth -= 1;
          }

          this.changedView();
        } if (next === buttonClicked) {
          v.target.nextMonth();

          if (this.currentMonth === 12) {
            this.currentMonth = 1;
            this.currentYear += 1;
          } else {
            this.currentMonth += 1;
          }

          this.changedView();
        }
      } else if (path.find((a) => a.className && a.className.includes('picker-item'))) {
        const { value } = path[0].dataset;

        if (value.length === 4) {
          this.currentYear = value;
        } else {
          this.currentMonth = value;
        }

        this.changedView();
      } else if (path.find((a) => a.className && a.className.includes('calendar-day'))) {
        this.value = moment({
          ...v.target.activePartsClone,
          month: v.target.activePartsClone.month - 1,
        }).format(this.fieldAttrs.format || 'YYYY-MM-DD');
      }
    },
    clickedTrigger() {
      this.currentMonth = moment(this.value).month() + 1;
      this.currentYear = moment(this.value).year();

      this.changedView();
    },
  },
  mounted() {
    this.changedView();
  },
};
</script>
<style scoped>
.hiddeIonLabel ion-label{
  display: none;
}
.hiddeIonLabel {
/* --min-height:0 */
/* opacity: 0; */
}
</style>